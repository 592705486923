<div class="report-type-container">
    <div class="report-type-header">
      <h4>Report Type:</h4>
    </div>
    <div class="report-type-selection">
      <select class="form-control" name="reportType" id="ddlreportType" [(ngModel)]="reportType" (change)="onChange()">
        <option value="home">Select Report Type</option>
        <option value="pcpmembershiproster">PCP Membership Roster</option>
        <option value="payforvalue">Pay for Value</option>
        <!--<option value="payforvaluemanual">Pay for Value - Manual</option>-->
        <option value="bloodleadscreening">Blood Lead Screening</option>
        <option *ngIf="showDHCSBLS" value="dhcsbloodleadscreening">DHCS Blood Lead Screening</option>
        <option value="pcpattestation">Primary Care Documentation Program</option>              
        <!--<option value="bhpayforvalue">BH Pay for Value</option>-->
        <option value="bhaba">BH ABA P4V Member Roster</option>
        <option value="awp">Medi-Cal Annual Wellness Visit</option>
        <option value="hif">Health Information Form</option>
        <option value="iha">Initial Health Appointment</option>
        <option value="bhi">BHI Quality Measure</option>
        <option *ngIf="showPDC" value="pdc">Proportion of Days Covered (PDC)</option>
        <!-- <option value="roster">Provider Member Roster</option> -->
      </select>
    </div>
    <div class="report-type-manageattestion">
      <h4><a href="javascript:void(0);" (click)="redirectToAttestation()">Manage Attestation</a></h4>
    </div>    
</div>

